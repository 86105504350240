import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import "./Navbar.css";
import SearchIcon from "@mui/icons-material/Search";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import Logo from "../../shared/images/logo.png";
import MenuTransitions from "./MenuTransitions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ModalSearchBox from "./ModalSearchBox";
import LocalPhone from "@mui/icons-material/LocalPhone";
import MegaMenu from "./MegaMenu";
import { useDispatch, useSelector } from "react-redux";
import { openCartModal, closeCartModal } from "../../redux/Slices/CartModal";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../redux/Slices/Language"; // Import setLanguage action
import { Select, MenuItem, IconButton } from "@mui/material";
import { Globe } from "lucide-react";
import Flag from "react-world-flags";
import { useGetAllHeaderMessagesQuery } from "../../redux/Apis/HeaderSettings";
import MobileMegaMenu from "./MobileMegaMenu";
import cartIcon from "../../shared/images/cartIcon.svg";
import axios from "axios";
// Lazy load CartModalRight
const CartModalRight = lazy(() => import("../Cart/CartRight"));

const Navbar = () => {
  const navbarCollapseRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isCartModalOpen = useSelector((state) => state.modal.isCartModalOpen);
  const { t } = useTranslation();
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const currentLanguage = useSelector((state) => state.language);
  const {
    data,
    error,
    isLoading,
    refetch: headerMessageFetch,
  } = useGetAllHeaderMessagesQuery();
  const [visibleMessages, setVisibleMessages] = useState([]);
  const collapseRef = useRef(null);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userTyped, setUserTyped] = useState(false);

  const fetchSearchSuggestions = async (term) => {
    if (term.length > 2) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/search/suggestions?term=${term}`
        );

        if (response.data.success) {
          const { products, categories, brands } =
            response.data.data.suggestions;

          const productSuggestions = products.map((p) => ({
            name: p.name,
            type: "product",
          }));

          const categorySuggestions = categories.map((c) => ({
            name: `Category: ${c.name}`,
            id: c.id,
            type: "category",
          }));

          const brandSuggestions = brands.map((b) => ({
            name: `Brand: ${b.name}`,
            id: b.id,
            type: "brand",
          }));

          setSuggestions([
            ...productSuggestions,
            ...categorySuggestions,
            ...brandSuggestions,
          ]);
        }
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 2 && userTyped) {
      const delayDebounce = setTimeout(() => {
        fetchSearchSuggestions(searchTerm);
        setShowSuggestions(true);
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setSuggestions([]);
      setUserTyped(false);
      setShowSuggestions(false);
    }
  }, [searchTerm]);

  const handleSuggestionClick = (item) => {
    let searchQuery = "";
  
    if (item.type === "category") {
      searchQuery = `category_id=${item.id}`;
    } else if (item.type === "brand") {
      searchQuery = `brand_id=${item.id}`;
    } else {
      searchQuery = `search=${item.name}`;
    }
  
    setSearchTerm(item.name);
    setSuggestions([]);
    setShowSuggestions(false);
    setUserTyped(false);
    navigate(`/products?${searchQuery}`);
  };  

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setUserTyped(true);

    if (value.length > 2) {
      fetchSearchSuggestions(value);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };
  const updateMenuVisibility = () => {
    const width = window.innerWidth;
    setIsMobileMenu(width <= 991);
  };

  useEffect(() => {
    updateMenuVisibility();
    window.addEventListener("resize", updateMenuVisibility);

    return () => {
      window.removeEventListener("resize", updateMenuVisibility);
    };
  }, []);

  const handleNavLinkClick = () => {
    if (collapseRef.current) {
      collapseRef.current.classList.remove("show");
    }
  };

  const fetchHeaderMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/header-message/get/`
      );
      const data = response?.data?.data;
      const filteredMessages = data
        ? data.filter((message) => message.show === true)
        : [];
      setVisibleMessages(filteredMessages);
    } catch (error) {
      console.error("Error fetching header messages:", error);
    }
  };

  useEffect(() => {
    fetchHeaderMessages();
  }, []);

  useEffect(() => {
    if (isLoading) {
      // Log when the query is loading
    } else if (data) {
      const filteredMessages = Array.isArray(data.data)
        ? data.data.filter((message) => message.show === true)
        : [];
      setVisibleMessages(filteredMessages);
    }
  }, [data, isLoading]);

  useEffect(() => {
    headerMessageFetch();
  }, [headerMessageFetch]);

  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang)); // Dispatch Redux action to change language
  };

  const cartItems = useSelector((state) => state.cartSlice.items);
  const totalItemsInCart = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleOutsideClick = (event) => {
    if (
      navbarCollapseRef.current &&
      !navbarCollapseRef.current.contains(event.target)
    ) {
      const isNavbarExpanded = navbarCollapseRef.current.querySelector(
        ".navbar-collapse.show"
      );
      if (isNavbarExpanded) {
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle) {
          navbarToggle.click(); // Trigger the collapse
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [navbarCollapseRef]);

  const isCheckoutRoute = location.pathname === "/auth/checkout";

  const handleCartClick = () => {
    if (!isCheckoutRoute) {
      dispatch(openCartModal());
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  const handleSelect = (language) => {
    handleLanguageChange(language);
    closeDropdown();
  };

  const toggleLanguage = () => {
    if (currentLanguage === "swe") {
      handleLanguageChange("en");
    } else {
      handleLanguageChange("swe");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/products?search=${searchTerm}`);
    }
  };

  useEffect(() => {
    if (visibleMessages.length > 0) {
      const interval = setInterval(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % visibleMessages.length
        );
      }, 3000); // Change message every 3 seconds

      return () => clearInterval(interval);
    }
  }, [visibleMessages]);

  return (
    <>
      <div className="">
        {isCartModalOpen && (
          <Suspense fallback={<div>Loading...</div>}>
            <CartModalRight
              isOpen={isCartModalOpen}
              onRequestClose={() => dispatch(closeCartModal())}
            />
          </Suspense>
        )}
        <div className="page-top">
          <p className="message">
            {visibleMessages?.length > 0 &&
              visibleMessages[currentMessageIndex]?.message}
          </p>
        </div>
        <nav className="navbar navbar-expand-lg " ref={navbarCollapseRef}>
          <div className="container-fluid">
            <div>
              <Link className="navbar-brand" to="/">
                <img src={Logo} className="logo" alt="Logo" />
              </Link>
            </div>

            <ul className="navbar-nav large-items">
              <div className="custom-search-container">
                <input
                  className="custom-search-input"
                  type="text"
                  placeholder="Sök efter produkter"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/products?search=${searchTerm}`);
                      setSuggestions([]);
                    }
                  }}
                />
                <button
                  className="custom-search-button"
                  onClick={() => navigate(`/products?search=${searchTerm}`)}
                >
                  <SearchIcon style={{ fontSize: "1.9rem" }} />
                </button>

                {showSuggestions && suggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {suggestions.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(item)}
                      >
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </ul>
            <div>
              <ul className="navbar-nav ms-auto mb-lg-0">
                <div className="icons-container">
                  <div className="small-items">
                    <SearchIcon
                      className="nav-icons nav-icon-container"
                      onClick={() => handleShowSearch()}
                    />
                  </div>
                  <MenuTransitions />
                  <div
                    className="cart-icon-container nav-icon-container"
                    onClick={handleCartClick}
                  >
                    <img
                      src={cartIcon}
                      height="35px"
                      // width="1.6rem"
                      className="nav-icons mt-1"
                      alt=""
                    />
                    <div className="cart-counter">{totalItemsInCart}</div>
                  </div>
                </div>
              </ul>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg py-0">
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            ref={collapseRef}
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link active"
                  aria-current="page"
                  onClick={handleNavLinkClick}
                >
                  {t("Home")}
                </Link>
              </li>
              <div className="nav-link-mega">
                {isMobileMenu ? <MobileMegaMenu /> : <MegaMenu />}
              </div>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/brands"
                  onClick={handleNavLinkClick}
                >
                  Varumärken
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  onClick={handleNavLinkClick}
                >
                  {t("About")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  onClick={handleNavLinkClick}
                >
                  {t("Contact")}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <ModalSearchBox showSearch={showSearch} />
    </>
  );
};

export default Navbar;
