import { Button } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React from "react";
import Logo from "../../shared/images/logo.png";
import moment from "moment";

const PDFGenerator = ({ data }) => {
  const date = new Date(data?.createdAt);
  const formattedDate = date.toLocaleDateString("en-GB");

  const generatePDF = () => {
    const doc = new jsPDF();

    //   // Define a fixed logo height and calculate width to maintain aspect ratio
    const logoHeight = 30;
    const logoWidth = 30;

    //   // Add logo and title
    doc.addImage(Logo, "PNG", 10, 10, logoWidth, logoHeight);
    doc.setFontSize(18);
    doc.text("Faktura", doc.internal.pageSize.width - 20, 20, {
      align: "right",
    });

    // Order ID
    doc.setFontSize(12);
    doc.text(`Beställningsdatum: ${formattedDate}`, 10, 50);

    doc.setFontSize(12);
    doc.text(`Ordernummer: ${data.orderShortId}`, 10, 55);

    doc.setFontSize(12);
    doc.text(`Fakturanummer: ${data.invoiceNumber}`, 10, 60);

    doc.setFontSize(12);
    doc.text(`Moms: SE559491833501`, 10, 65);

    // User Details Table
    doc.autoTable({
      startY: 70,
      theme: "grid",
      head: [["Användarinformation", ""]],
      body: [
        ["Namn", `${data.userId.first_name} ${data.userId.last_name}`],
        ["E-post", data.userId.email],
      ],
      headStyles: { fillColor: [225, 225, 225] },
    });

    // Shipping Details Table
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      theme: "grid",
      head: [["Leveransdetaljer", ""]],
      body: [
        ["Adress", data.shippingAddress.addressLine1],
        ["Postnummer", data.shippingAddress.postalCode],
        ["Telefon", data.shippingAddress.phone],
      ],
      headStyles: { fillColor: [225, 225, 225] },
    });

    // Payment and Shipping Status Table
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      theme: "grid",
      head: [["Betalnings- och leveransstatus", ""]],
      body: [
        ["Betalningsmetod", data.paymentMethod],
        ["Betalningsstatus", data.paymentStatus],
        ["Leveransstatus", data.shippingStatus],
      ],
      headStyles: { fillColor: [225, 225, 225] },
    });

    // Order Items Table
    const items = data.items.map((item) => [
      item.productId?.name || "Unknown Product", // ✅ Safe fallback
      item.quantity || 0,
      `${(item.price / 1.25)?.toFixed(2) || "0.00"} kr`,
      "25 %",
      `${item.totalItemPrice?.toFixed(2) || "0.00"} kr`,
    ]);

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      theme: "grid",
      head: [
        [
          "Produkt",
          "Kvantitet",
          "Pris (exkl. moms)",
          "Moms",
          "Pris (inkl. moms)",
        ],
      ],
      body: [
        ...items,
        ["", "", "", "", ""],
        [
          { content: "Total frakt", styles: { fontStyle: "bold" } },
          "",
          "",
          "",
          {
            content: `${data.shippingOption?.cost?.toFixed(2)} kr`,
            styles: { fontStyle: "bold" },
          },
        ],
      ],
      headStyles: { fillColor: [225, 225, 225] },
    });

    // Summary Table on Right Side
    const summaryX = 100; // Adjust this to align on the right

    doc.setFontSize(12);
    doc.text(
      `Totalsumma för faktura: ${data.totalAmount.toFixed(2)} kr`,
      summaryX,
      doc.lastAutoTable.finalY + 8
    );

// Initialize totals
let totalExcludingVAT = 0;
let totalVATAmount = 0;

// Calculate values from data.items
data.items.forEach((item) => {
  const priceExVAT = item.price / 1.25; // Calculate price excluding VAT
  const vatAmount = item.price - priceExVAT; // Calculate VAT amount

  totalExcludingVAT += priceExVAT;
  totalVATAmount += vatAmount;
});

// Generate Table with Updated Calculations
doc.autoTable({
  startY: doc.lastAutoTable.finalY + 15, // Place below the main table
  margin: { left: summaryX }, // Move table to the right
  theme: "grid",
  head: [["", "Moms", "Delsumma för artikel (exkl. moms)", "Moms Belopp"]], // Headers
  body: [
    [
      "",
      "25 %",
      `${totalExcludingVAT.toFixed(2)} kr`, // Sum of all item prices excluding VAT
      `${totalVATAmount.toFixed(2)} kr`, // Total VAT amount
    ],
    ["", "", "", ""], // Blank row for spacing
    [
      { content: "Totalt", styles: { fontStyle: "bold" } },
      "",
      { content: `${totalExcludingVAT.toFixed(2)} kr`, styles: { fontStyle: "bold" } }, // Total price excluding VAT
      { content: `${totalVATAmount.toFixed(2)} kr`, styles: { fontStyle: "bold" } }, // Total VAT amount
    ], // Total row with updated calculations
  ],
  headStyles: { fillColor: [225, 225, 225] }, // Light grey header
  styles: { halign: "right" }, // Align text to the right
});


    // Save the PDF
    doc.save("order-summary.pdf");
  };

  return (
    <Button
      variant="outlined"
      color="info"
      className="print-order-button"
      onClick={generatePDF}
    >
      Skriv ut beställning
    </Button>
  );
};

export default PDFGenerator;
