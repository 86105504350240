import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const KupongApiService = createApi({
  reducerPath: "kuponApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}` }), // Base URL for your API
  endpoints: (builder) => ({
    getApplyKupong: builder.mutation({
      query: (body) => ({
        url: "/kupong/applyKupong",
        method: "POST",
        body, // Pass the request body here
      }),
    }),
  }),
});

export const { useGetApplyKupongMutation } = KupongApiService;