import React from "react";
import { Divider, Box, Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OrderDetails = React.memo(({ cartData, isLoggedIn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <Paper
      sx={{
        padding: 2,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid #ddd",
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        {t("YourOrder")}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {cartData?.items && cartData.items.length > 0 ? (
        cartData.items.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              paddingY: 2,
              borderBottom: "1px solid #eee",
              "&:last-child": { borderBottom: "none" },
              "@media (max-width: 600px)": {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            <img
              src={item.productId.images[0] || "default-image.jpg"}
              alt={item.productId.name}
              style={{
                width: 60,
                height: 60,
                borderRadius: 8,
                marginRight: 12,
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
              }}
              onClick={() => handleNavigate(item.productId._id)}
            />
            <Box
              sx={{
                flex: 1,
                minWidth: 0,
                "@media (max-width: 500px)": { width: "100%", marginTop: 1 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  cursor: "pointer",
                  color: "#333",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "50%", // Ensures it doesn’t expand
                }}
                onClick={() => handleNavigate(item.productId._id)}
              >
                {item.productId.name || "Product Name"}
              </Typography>

              <Typography
                variant="body2"
                sx={{ color: "#999", fontSize: "0.875rem" }}
              >
                Quantity: {item.quantity}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#333", fontWeight: 600 }}
              >
                {`${item.totalItemPrice.toFixed(2)} kr`}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <>
        {isLoggedIn && <Typography variant="body2" sx={{ color: "#999" }}>
          {t("YourCartIsEmpty")}
        </Typography>}
        </>
      )}

      {!isLoggedIn && cartData?.length > 0 ? (
        cartData?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              paddingY: 2,
              borderBottom: "1px solid #eee",
              "&:last-child": { borderBottom: "none" },
              "@media (max-width: 600px)": {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            <img
              src={item.imageUrl || "default-image.jpg"}
              alt={item.name}
              style={{
                width: 60,
                height: 60,
                borderRadius: 8,
                marginRight: 12,
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
              }}
              onClick={() => handleNavigate(item.productId)}
            />
            <Box
              sx={{
                flex: 1,
                minWidth: 0,
                "@media (max-width: 500px)": { width: "100%", marginTop: 1 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  cursor: "pointer",
                  color: "#333",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "50%", // Ensures it doesn’t expand
                }}
                onClick={() => handleNavigate(item.productId)}
              >
                {item.name || "Product Name"}
              </Typography>

              <Typography
                variant="body2"
                sx={{ color: "#999", fontSize: "0.875rem" }}
              >
                Quantity: {item.quantity}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#333", fontWeight: 600 }}
              >
                {`${item.discountedPrice.toFixed(2)} kr`}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <>
        {!isLoggedIn && <Typography variant="body2" sx={{ color: "#999" }}>
          {t("YourCartIsEmpty")}
        </Typography>}
        </>
      )}
    </Paper>
  );
});

export default OrderDetails;
