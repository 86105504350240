import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const ReturnsRefundPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        Fraktoch Returer
      </Typography>
      <Typography variant="body1" paragraph>
        Denna retur- och återbetalningspolicy ("Policy") är en del av villkoren
        för Swedebuy AB ("vi", "oss", "vår"). Den beskriver villkoren och
        procedurerna för att returnera produkter köpta från vår onlinebutik och
        få en återbetalning. Genom att göra ett köp accepterar du ("kund", "du",
        "din") att följa denna policy.
      </Typography>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          1. Berättigande för returer
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Tidsram: Produkter måste returneras inom 14 dagar från mottagandet." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Produkters skick: För att vara berättigad till retur måste produkterna vara oanvända, i samma skick som du tog emot dem och i originalförpackningen." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Ej-returnerbara föremål: Vissa föremål är inte berättigade att returneras, inklusive men inte begränsat till ömtåliga varor, anpassade föremål, presentkort och nedladdningsbara mjukvaruprodukter. Dessa kommer att vara tydligt markerade på vår webbplats." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          2. Returprocess
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Initiera en retur: För att initiera en retur, kontakta vår kundtjänst på info@swedebuy.com med ditt beställningsnummer och information om produkten du vill returnera. Vi kommer att förse dig med ett RMA-nummer (Return Merchandise Authorization) och instruktioner om hur du går tillväga." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Kunden kommer även att kunna initiera returprocessen med sitt eget användarkonto. Han kommer dock inte att returnera någon av sina produkter utan att ha fått returgodkännande från oss." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Returfrakt: Du är ansvarig för att betala fraktkostnaderna för att returnera din vara. Fraktkostnader återbetalas inte såvida inte returen beror på ett fel från vår sida (t.ex. att du fått en felaktig eller defekt vara)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Returbehörighet: Alla returer måste godkännas av vår kundtjänst och åtföljas av ett RMA-nummer. Otillåtna returer kommer inte att accepteras." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          3. Inspektion och godkännande
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Inspektion av returer: När din retur har mottagits och inspekterats kommer vi att meddela dig om godkännandet eller avslagandet av din återbetalning. Denna process tar vanligtvis 14 dagar." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Godkännandekriterier: Produkter måste uppfylla våra behörighetskriterier för att godkännas för återbetalning. Föremål som är skadade, inte i originalförpackningen, eller som visar tecken på användning kommer inte att vara berättigade till återbetalning." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          4. Återbetalningsprocessen
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Behandlingstid: Om din retur godkänns kommer din återbetalning att behandlas inom 14 dagar efter att vi mottagit den returnerade produkten." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Återbetalningsmetod: Återbetalningar kommer att utfärdas till den ursprungliga betalningsmetod som användes för köpet. Om detta inte är möjligt kommer vi att kontakta dig för att ordna en alternativ metod." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Delvis återbetalning: I vissa situationer beviljas endast partiella återbetalningar (t.ex. varor som returneras efter returperioden eller varor som inte är i originalskick)." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          5. Utbyten
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Byten: Om du behöver byta en produkt mot samma vara på grund av storlek, färg eller andra skäl, vänligen kontakta vår kundtjänst. Utbyten är beroende av produktens tillgänglighet. Alla kostnader som uppstår till följd av denna ändring (återlämnande av originalvaran och sändning av ersättningsvaran) kommer dock att bäras av kunden." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          6. Skadade eller defekta produkter
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Rapportera skador: Om du får en skadad eller defekt produkt, vänligen kontakta oss omedelbart på service@swedebuy.com med ditt ordernummer och en beskrivning av problemet." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Ersättning eller återbetalning: Vi kommer att ordna ett utbyte eller återbetalning utan extra kostnad för dig, förutsatt att skadan eller defekten rapporteras inom 30 dagar efter mottagandet." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          7. Ej återbetalningsbara föremål
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Presentkort: Presentkort är ej återbetalningsbara." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Nedladdningsbar programvara: Digitala produkter som har laddats ner är ej återbetalningsbara." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Reavaror: Endast ordinarie prissatta varor kan återbetalas; reavaror kan inte återbetalas." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          8. Kontaktinformation
        </Typography>
        <Typography variant="body1">
          Kundtjänst: För eventuella frågor eller funderingar angående denna
          policy, vänligen kontakta vår kundtjänst på{" "}
          <strong>service@swedebuy.com</strong>.
        </Typography>
      </Box>
    </Container>
  );
};

export default ReturnsRefundPolicy;
