import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "./Navbar.css";
import axios from "axios";

const ModalSearchBox = ({ showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State to manage the search term
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const fetchSearchSuggestions = async (term) => {
    if (term.length > 2) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/search/suggestions?term=${term}`
        );

        if (response.data.success) {
          const { products, categories, brands } =
            response.data.data.suggestions;

          const productSuggestions = products.map((p) => ({
            name: p.name,
            type: "product",
          }));

          const categorySuggestions = categories.map((c) => ({
            name: `Category: ${c.name}`,
            id: c.id,
            type: "category",
          }));

          const brandSuggestions = brands.map((b) => ({
            name: `Brand: ${b.name}`,
            id: b.id,
            type: "brand",
          }));

          setSuggestions([
            ...productSuggestions,
            ...categorySuggestions,
            ...brandSuggestions,
          ]);
        }
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const delayDebounce = setTimeout(() => {
        fetchSearchSuggestions(searchTerm);
        setShowSuggestions(true);
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchTerm]);

  const handleSuggestionClick = (item) => {
    let searchQuery = "";

    if (item.type === "category") {
      searchQuery = `category_id=${item.id}`;
    } else if (item.type === "brand") {
      searchQuery = `brand_id=${item.id}`;
    } else {
      searchQuery = `search=${item.name}`;
    }

    setSearchTerm(item.name);
    setSuggestions([]);
    setShowSuggestions(false);
    navigate(`/products?${searchQuery}`);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 2) {
      fetchSearchSuggestions(value);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    if (showSearch) {
      setIsOpen(true);
    }
  }, [showSearch]);

  const handleClose = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <div id="search-box" className={isOpen ? "-open" : ""}>
        <div className="container">
          <a className="close" href="#close" onClick={handleClose}></a>
          <div className="search-main">
            <div className="search-inner">
              <form>
                <input
                  type="text"
                  id="inputSearch"
                  name="search"
                  autoComplete="off"
                  placeholder="Sök ..."
                  value={searchTerm} // Bind the input value to searchTerm
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/products?search=${searchTerm}`);
                      setSuggestions([]);
                    }
                  }}
                />
              </form>
              {showSuggestions && suggestions.length > 0 && (
                <ul className="suggestions-list-modal">
                  {suggestions.map((item, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalSearchBox;
