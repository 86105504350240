import { createSlice } from "@reduxjs/toolkit";

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    code: null,
    expiresAt: null,
    isValid: false
  },
  reducers: {
    applyCoupon: (state, action) => {
      state.code = action.payload.code;
      // Set expiration to 5 minutes from now
      state.expiresAt = Date.now() + 5 * 60 * 1000;
    },
    removeCoupon: (state) => {
      state.code = null;
      state.expiresAt = null;
    }
  }
});

export const { applyCoupon, removeCoupon } = couponSlice.actions;
export default couponSlice.reducer;
