import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CartProductWithDelete from "../Cart/CartProductDelete";
import { useGetRandomQuery } from "../../redux/Apis/Products"; // Adjust the path
import "./CartRight.css";
import {
  removeItemFromCart,
  updateItemQuantity,
} from "../../redux/Slices/Cart";
import { useTranslation } from "react-i18next";
import ProductCard from "../Trending/ProductCard";

const CartModalRight = ({ isOpen, onRequestClose }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { t } = useTranslation();

  const {
    data: randomProductsResponse,
    error,
    isLoading,
  } = useGetRandomQuery();
  const randomProducts = randomProductsResponse?.data || []; // Ensure it's an array

  const cartItems = useSelector((state) =>
    isOpen ? state.cartSlice.items : []
  );
  // const isCookieConsentAccepted = useSelector(
  //   (state) => state.cookieConsent.isAccepted
  // );

  // useEffect(() => {
  //   if (isCookieConsentAccepted) {
  //     // Retrieve the main recently viewed product IDs cookie
  //     let productIds = [];
  //     const mainCookie = Cookies.get("recentlyViewedProducts");
  //     if (mainCookie) {
  //       try {
  //         productIds = JSON.parse(mainCookie);
  //       } catch (e) {
  //         console.error("Error parsing main cookie:", e);
  //       }
  //     }

  //     // Ensure productIds is an array
  //     if (!Array.isArray(productIds)) {
  //       productIds = []; // Reset to an empty array if data is malformed
  //     }

  //     // Load each product's data from its individual cookie
  //     const recentlyViewedProducts = productIds
  //       .map((id) => {
  //         const productCookie = Cookies.get(`recentlyViewedProduct_${id}`);
  //         return productCookie ? JSON.parse(productCookie) : null;
  //       })
  //       .filter((product) => product !== null);

  //     // Set the recently viewed products state with the retrieved data
  //     setRecentlyViewedProducts(recentlyViewedProducts);
  //   }
  // }, [isCookieConsentAccepted]);

  const subtotal = useMemo(() => {
    if (!isOpen) return 0;
    return cartItems.reduce((total, item) => {
      const itemTotal = item.basePrice * item.quantity;
      const discountedTotal =
        item.quantityDiscount && item.quantity >= item.quantityDiscount.quantity
          ? itemTotal * (1 - item.quantityDiscount.discount / 100)
          : itemTotal;

      return total + discountedTotal;
    }, 0);
  }, [cartItems, isOpen]);

  const onDelete = useCallback(
    (itemData) => {
      if (isOpen) {
        dispatch(removeItemFromCart(itemData));
        toast.success("Produkten borttagen från kundvagnen");
      }
    },
    [dispatch, isOpen]
  );

  const onQuantityChange = useCallback(
    (itemData) => {
      if (isOpen) {
        dispatch(updateItemQuantity(itemData));
      }
    },
    [dispatch, isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <>
          <div
            className={`cart-overlay ${isOpen ? "open" : ""}`}
            onClick={onRequestClose}
          ></div>
          <div className={`cart-modal ${isOpen ? "open" : ""}`}>
            <div className="cart-modal-content">
              <button className="close-btn" onClick={onRequestClose}>
                &times;
              </button>
              <h3 className="cart-heading">
                {t("Products")} ({cartItems.length})
              </h3>
              <Divider className="my-3" sx={{ borderColor: "#000" }} />
              <div className="cart-items-container">
                {cartItems.length === 0 ? (
                  <div className="empty-cart">
                    <p>{t("OopsAdd")}</p>
                  </div>
                ) : (
                  cartItems.map((item, index) => (
                    <CartProductWithDelete
                      key={index}
                      data={item}
                      onDelete={() => onDelete(item)}
                      onQuantityChange={(updatedItem) =>
                        onQuantityChange(updatedItem)
                      }
                    />
                  ))
                )}
              </div>

              <Divider className="my-3" sx={{ borderColor: "#000" }} />
              <div className="cart-pricing">
                <p>Delsumma</p>
                <p>{`${subtotal.toFixed(2)} kr`}</p>
              </div>
              <div className="cart-pricing">
                <p>Leverans</p>
                <p style={{textAlign:"end"}}>Leveranskostnaderna är inte beräknade än</p>
              </div>
              <Divider className="my-3" sx={{ borderColor: "#000" }} />
              <div className="cart-actions">
                <button className="checkout-btn" onClick={onRequestClose}>
                  {t("ContinueShopping")}
                </button>
                <button
                  className="checkout-btn"
                  onClick={() => {
                    nav("/cart");
                    onRequestClose();
                  }}
                  disabled={cartItems.length === 0}
                >
                  {cartItems.length === 0 ? t("AddProdCheck") : t("Checkout")}
                </button>
              </div>

              {/* Recommended products section */}
              {cartItems.length > 0 && (
                <>
                  <h4 className="recommended-heading">{t("Reccomended")}</h4>
                  <div className="recommended-products">
                    {isLoading ? (
                      <p>Loading recommended products...</p>
                    ) : error ? (
                      <p>Error loading recommended products.</p>
                    ) : (
                      randomProducts.map((product) => (
                        <ProductCard key={product._id} data={product} />
                      ))
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CartModalRight;
