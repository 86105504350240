import React, { useState } from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetNewsletterQuery,
  useSubscribeToNewsletterMutation,
} from "../../redux/Apis/Newsletter";
import { toast } from "react-toastify";


const Footer = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: newsletterData, isLoading, error } = useGetNewsletterQuery();
  const [subscribeToNewsletter] = useSubscribeToNewsletterMutation();

  const newsletter = newsletterData?.data?.[0];

  const handleSubmit = async (e) => {
    if (newsletter?._id && email) {
      setIsSubmitting(true);
      try {
        await subscribeToNewsletter({ id: newsletter._id, email }).unwrap();
        toast.success("Tack för att du prenumererar på Swedebuy");
        setEmail("");
        setIsSubmitting(false);
      } catch (error) {
        console.error("Subscription failed:", error);
        toast.error(error);
      }
    }
  };

  const selectedLanguage = useSelector((state) => state.language);
  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";

  return (
    <footer className={`footer`}>
      <div className="text-center text-md-start p-5">
        <div className="footer-wrap">
          <div className="company">
            <h3 style={{ fontWeight: "normal", marginBottom: "20px" }}>
              {t("Information")}
            </h3>
            <p>
              <a href="#" onClick={() => nav("/return")}>
                {t("ShippingReturn")}
              </a>
            </p>
            <p>
              <a href="#" onClick={() => nav("/terms")}>
                {t("Terms")}
              </a>
            </p>
            <p>
              <a href="#" onClick={() => nav("/privacy")}>
                {t("Privacy Policy")}
              </a>
            </p>
          </div>
          <div className="company">
            <h3 style={{ fontWeight: "normal", marginBottom: "20px" }}>
              {t("HelpSupport")}
            </h3>
            <p>
              <a href="#" onClick={() => nav("/contact")}>
                {t("about.contactUs.heading")}
              </a>
            </p>
          </div>
          <div className="about">
            <h2 style={{ marginBottom: "20px", fontWeight: "bold" }}>
              {t("NewsLetterSignUp")}
            </h2>
            <p style={{ letterSpacing: "2px" }}>
              {t("about.newsLetter.heading")}
            </p>
            <div className="custom-subscribe-container">
              <input
                className="custom-subscribe-input"
                type="text"
                placeholder={t("Email Address")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="custom-subscribe-button"
                style={{
                  borderRadius: "25px 0 0 25px",
                  borderLeft: "2px solid black",
                }}
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
               {isSubmitting ? "Prenumererar..." : "Anmäl dig"}
              </button>
            </div>
            <div className="social-media">
              <p style={{ fontSize: "18px", marginBottom: "0px" }}>Följ oss</p>
              <div>
                <a
                  href="https://www.facebook.com/profile.php?id=61574196843197"
                  target="_blank"
                  className="me-2 text-reset"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/swedebuy?igsh=dzV2dnk3YnU5bnl6"
                  target="_blank"
                  className="me-2 text-reset"
                >
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
